[class*="m-ratio--"] {
    display: block;
    overflow: hidden;

    &::before {
        content: "";
        float: left;
    }
}

.m-ratio {
    @each $type, $ratios in $picture-ratios {
        &--#{$type}::before {
            @each $breakpoint, $ratio in $ratios {
                @if ($breakpoint == default) {
                    padding-bottom: $ratio;
                } @else {
                    @media (--#{$breakpoint}) {
                        padding-bottom: $ratio;
                    }
                }
            }
        }
    }
}
