@mixin animated-link-color($color: $black) {
    &,
    &:hover {
        color: $color;
    }

    &::before {
        background-color: $color;
    }
}

@mixin animated-link($color: $black, $hover-selector: ".non-touch &:hover", $invert: false) {
    @include animated-link-color($color);

    cursor: pointer;
    line-height: 1.2;
    position: relative;
    transition: opacity $transition-ease;

    &::before {
        bottom: 1px;
        height: 1px;
        position: absolute;

        @if ($invert) {
            right: 0;
            width: 100%;
        } @else {
            left: 0;
            width: 0;
        }
    }

    &,
    &:hover {
        @if ($invert) {
            text-decoration: underline;
        } @else {
            text-decoration: none;
        }
    }

    @if ($hover-selector) {
        #{$hover-selector} {
            opacity: 0.5;
        }
    }
}

@mixin animated-link-hover($hover-selector: ".non-touch &:hover", $invert: false) {
    &::before {
        content: "";
        transition: width 0.5s ease-in-out;
    }

    &,
    &:hover {
        text-decoration: none;
    }

    @if ($hover-selector) {
        #{$hover-selector} {
            opacity: 1;

            &::before {
                @if ($invert) {
                    width: 0;
                } @else {
                    width: 100%;
                }
            }
        }
    }
}

@mixin line-clamping($lines: 2, $line-height: false) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    overflow: hidden;
    text-overflow: ellipsis;

    @if $line-height {
        max-height: $lines * $line-height;
    }
}

@mixin modal-background() {
    background-color: $black;
    height: 120%;
    left: -10%;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: -10%;
    transition: opacity $transition-ease, z-index linear 0s $transition-timing;
    width: 120%;
    z-index: $z-modal-bg;
}

$baseFontSize: 12px;

@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @return $pixels / $context * 1rem;
}

@mixin custom-scrollbar(
    $bg-color: $white,
    $thumb-color: $gray-300,
    $scrollbar-width: 6px,
    $scrollbar-height: 6px,
    $arrows: true
) {
    &::-webkit-scrollbar {
        height: $scrollbar-height;
        width: $scrollbar-width;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: 10px;
        margin: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $bg-color;
        border-radius: 10px;
    }

    @if $arrows {
        &::-webkit-scrollbar-button {
            background: $bg-color;
            height: $scrollbar-height;
            width: $scrollbar-width;
        }
    }
}

@mixin generate-classes(
    $class: "",
    $property: "",
    $values: ()
) {
    .#{$class} {
        @each $key, $value in $values {
            &-#{$key} {
                #{$property}: $value !important;
            }
        }

        @each $media, $width in $layout-breakpoints {
            @each $key, $value in $values {
                @media (--#{$media}-up) {
                    &-#{$media}-#{$key} {
                        #{$property}: $value !important;
                    }
                }
            }
        }
    }
}

@mixin setup-grid-gutters($gutters: (), $all: true) {
    @each $media, $gutter in $gutters {
        $gutter-half: $gutter * 0.5;

        @media (--#{$media}) {
            .row {
                @if ($all) {
                    margin: -#{$gutter-half};
                } @else {
                    margin-left: -#{$gutter-half};
                    margin-right: -#{$gutter-half};
                }
            }

            .col,
            [class*=col-] {
                @if ($all) {
                    padding: $gutter-half;
                } @else {
                    padding-left: $gutter-half;
                    padding-right: $gutter-half;
                }
            }
        }
    }
}

@mixin toggle-header {
    @include icons(arrow-down);

    cursor: pointer;
    font-size: 14px;
    font-weight: 300;

    @if ($feature-redesign) {
        font-weight: normal;
    }

    letter-spacing: 0.08em;
    line-height: 18px;
    padding: 25px 20px 20px 0;
    position: relative;
    text-transform: uppercase;
    
    @if ($feature-redesign) {
        text-transform: none;
    }

    @media (--md-down) {
        font-size: 12px;
    }

    &::before {
        font-size: 7px;
        margin-top: -2px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: rotate(-90deg);
        transition: transform $transition-ease;
    }

    &.m-active {
        &::before {
            transform: rotate(0deg);
        }
    }
}

@mixin toggle-header--refinement {
    @include icons(arrow-down, after) {
        font-size: 7px;
        transform: rotate(-90deg);

        @media (--lg-up) {
            margin-right: 8px;
        }
    }

    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 500;

    @if ($feature-redesign) {
        font-weight: normal;
    }
    justify-content: space-between;
    letter-spacing: 0.03em;
    line-height: normal;

    &.m-active {
        &::after {
            transform: rotate(0deg);
        }
    }
}

@mixin toggle-content(
    $padding-top: 0,
    $padding-bottom: 0,
    $animate-opacity: false
) {
    overflow: hidden;
    transition: height $transition-ease;

    @if ($animate-opacity) {
        opacity: 1;
        transition-property: height, opacity;

        &[hidden] {
            display: block !important;
            height: 0 !important;
        }

        &.hide {
            opacity: 0;
        }
    }

    @if strip-unit($padding-top) > 0 {
        &::before {
            content: "";
            display: block;
            height: $padding-top;
        }
    }

    @if strip-unit($padding-bottom) > 0 {
        &::after {
            content: "";
            display: block;
            height: $padding-bottom;
        }
    }
}

@mixin soft-hidden {
    display: block !important;

    &[hidden="hidden"] {
        height: 0;
        overflow: hidden;
    }
}
