.b-svg-image {
    display: inline-block;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    vertical-align: middle;
    width: 100%;

    image,
    svg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
