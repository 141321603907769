@mixin h1-1 {
    font-family: $font-family-tiempos-fine;
    font-size: 36px;
    font-weight: 700;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.03em;
    line-height: 1.19;

    @media (--sm) {
        font-size: 30px;
    }
}

@mixin h1-2 {
    font-family: $font-family-tiempos-fine;
    font-size: 36px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.03em;
    line-height: 1.19;
}

@mixin h1-3 {
    font-family: $font-family-tiempos-fine;
    font-size: 36px;
    font-weight: 700;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.03em;
    line-height: 1.19;

    @media (--md-down) {
        font-size: 32px;
    }
}

@mixin h1-4 {
    font-family: $font-family-tiempos-fine;
    font-size: 36px;
    font-weight: 700;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.03em;
    line-height: 1.19;

    @media (--md-down) {
        font-size: 26px;
    }
}

@mixin h1-checkout {
    font-family: $font-family-tiempos-fine;
    font-size: 32px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.05em;
    line-height: 1;

    @media (--md) {
        font-size: 26px;
    }

    @media (--sm) {
        font-size: 24px;
    }
}

@mixin h2 {
    font-family: $font-family-tiempos-fine;
    font-size: 32px;
    font-weight: 700;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.03em;
    line-height: 1.19;

    @media (--md) {
        font-size: 22px;
    }

    @media (--sm) {
        font-size: 26px;
    }
}

@mixin h2-checkout {
    font-family: $font-family-theinhardt;
    font-size: 22px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-sans;
        font-weight: normal;
    }

    letter-spacing: 0.05em;
    line-height: 1;
}

@mixin h3-1 {
    font-family: $font-family-tiempos-fine;
    font-size: 26px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: normal;
    }
    letter-spacing: 0.03em;
    line-height: 1.23;
}

@mixin h3-2 {
    font-family: $font-family-tiempos-fine;
    font-size: 26px;
    font-weight: 700;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.03em;
    line-height: 1.23;

    @media (--md) {
        font-size: 22px;
    }

    @media (--sm) {
        font-size: 26px;
    }
}

@mixin h3-checkout {
    font-family: $font-family-tiempos-fine;
    font-size: 22px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.03em;
    line-height: 1;

    @media (--md-down) {
        font-size: 20px;
    }
}

@mixin h4 {
    font-family: $font-family-tiempos-fine;
    font-size: 22px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: normal;
    }
    letter-spacing: 0.03em;
    line-height: 1.23;

    @media (--md-down) {
        font-size: 20px;
    }
}

@mixin h4-checkout {
    font-family: $font-family-theinhardt;
    font-size: 18px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-sans;
        font-weight: normal;
    }
    letter-spacing: 0.08em;
    line-height: 1;
}

@mixin h5-1 {
    font-family: $font-family-tiempos-fine;
    font-size: 25px;
    font-weight: 300;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: normal;
    }
    letter-spacing: 0.05em;
    line-height: 1.2;

    @media (--xxl) {
        letter-spacing: 2px;
    }
}

@mixin h5-2 {
    font-family: $font-family-theinhardt;
    font-size: 20px;
    font-weight: 300;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: normal;
        font-size: 17px;
        margin-top: 2rem;
    }
    letter-spacing: 0.08em;
    line-height: 1.4;
}

@mixin h5-checkout {
    font-family: $font-family-tiempos-fine;
    font-size: 20px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.015em;
    line-height: 1;

    @media (--lg) {
        font-size: 16px;
    }

    @media (--md) {
        font-size: 14px;
    }

    @media (--sm) {
        font-size: 12px;
    }
}

@mixin h6 {
    font-family: $font-family-theinhardt;
    font-size: 16px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-sans;
        font-weight: normal;
    }
    letter-spacing: 0.08em;
    line-height: 1.38;
}

@mixin h6-checkout {
    font-family: $font-family-tiempos-fine;
    font-size: 15px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.08em;
    line-height: 1;
    text-transform: uppercase;
}

@mixin h7 {
    font-family: $font-family-tiempos-fine;
    font-size: 16px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.04em;
    line-height: 1.19;
}

@mixin h8 {
    font-family: $font-family-theinhardt;
    font-size: 12px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-sans;
        font-weight: normal;
    }
    letter-spacing: 0.08em;
    line-height: 1.33;

    @media (--sm) {
        font-size: 14px;
    }
}

@mixin h-special {
    font-family: $font-family-beyond-infinity;
    font-size: 100px;
    font-weight: 400;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: normal;
    }
    letter-spacing: 0;
    line-height: 1.2;

    @media (--sm) {
        font-size: 60px;
    }
}

@mixin h-styles {
    h1 {
        @include h1-1;
    }

    h2 {
        @include h2;
    }

    h3 {
        @include h3-1;
    }

    h4 {
        @include h4;
    }

    h5 {
        @include h5-1;
    }

    h6 {
        @include h6;
    }
}

@mixin n1 {
    font-family: $font-family-serif;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.8rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 3.375rem;
        line-height: 4rem;
    }
}

@mixin n2 {
    font-family: $font-family-sans;
    font-size: 2.24rem;
    font-weight: normal;
    line-height: 2.8rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 3.25rem;
        line-height: 4rem;
    }
}

@mixin n3 {
    font-family: $font-family-serif;
    font-size: 1.76rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.75rem;
        line-height: 2.1875rem;
    }
}

@mixin n4 {
    font-family: $font-family-sans;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 2.2rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.625rem;
        line-height: 2.1875rem;
    }
}

@mixin n5 {
    font-family: $font-family-serif;
    font-size: 1.28rem;
    font-weight: 500;
    line-height: 1.76rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.25rem;
        line-height: 1.5625rem;
    }
}

@mixin n6 {
    font-family: $font-family-sans;
    font-size: 1.16rem;
    font-weight: normal;
    line-height: 1.6rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.1875rem;
        line-height: 1.5625rem;
    }
}

@mixin n7 {
    font-family: $font-family-serif;
    font-size: 1.36rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.0625rem;
        line-height: 1.5rem;
    }
}

@mixin n7-rp2 {
    font-family: $font-family-serif;
    font-size: 1.04rem;
    font-weight: normal;
    line-height: 1.3rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.0625rem;
        line-height: 1.4375rem;
    }
}

@mixin n8 {
    font-family: $font-family-sans;
    font-size: 1.04rem;
    font-weight: normal;
    line-height: 1.3rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1.0625rem;
        line-height: 1.4375rem;
    }
}

@mixin n9 {
    font-family: $font-family-serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    letter-spacing: 0px;

    @media (--md-up) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@mixin n10 {
    font-family: $font-family-sans;
    font-size: 0.96rem;
    font-weight: 500;
    line-height: 1.3rem;
    letter-spacing: 0px;


    @media (--md-up) {
        font-size: 0.9375rem;
        line-height: 1.25rem;
    }
}

@mixin n11 {
    font-family: $font-family-serif;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.8rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @media (--md-up) {
        font-size: 0.875rem;
        line-height: 100%;
    }
}

@mixin n12 {
    font-family: $font-family-serif;
    font-size: 1.158rem;
    font-weight: 450;
    line-height: 1.481rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @media (--lg) {
        font-size: 1.33rem;
        line-height: 1.667rem;
    }

    @media (--md) {
        font-size: 1.067rem;
        line-height: 1.33rem;
    }
}

@mixin d1 {
    font-family: $font-family-serif;
    font-size: 3.68rem;
    line-height: 4.16rem;
    font-weight: 450;

    @media (--md-up) {
        font-size: 5.312rem;
        line-height: 5.5rem;
    }
}

@mixin d2 {
    font-family: $font-family-sans;
    font-size: 3.2rem;
    line-height: 3.68rem;
    font-weight: 450;

    @media (--md-up) {
        font-size: 3.37rem;
        line-height: 3.76rem;
    }

    @media (--lg-up) {
        font-size: 5rem;
        line-height: 5.5rem;
    }
}

.n1 {
    @include n1;
}

.n2 {
    @include n2;
}

.n3 {
    @include n3;
}

.n4 {
    @include n4;
}

.n5 {
    @include n5;
}

.n6 {
    @include n6;
}

.n7 {
    @include n7;
}

.n8 {
    @include n8;
}

.n9 {
    @include n9;
}

.n10 {
    @include n10;
}

.n11 {
    @include n11;
}

.n12 {
    @include n12;
}

.d1 {
    @include d1;
}

.d2 {
    @include d2;
}
