.a-greyscale-hover {
    transition: filter $transition-ease;

    &:not(:hover) {
        filter: grayscale(100);

        image {
            filter: url(#grayscale);
        }
    }
}

.a-greyscale-scroll {
    transition: filter $transition-ease-long;

    &:not(.m-animate) {
        filter: grayscale(100);

        image {
            filter: url(#grayscale);
        }
    }
}
