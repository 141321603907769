$heights: (
    100: 100px
);

@include generate-classes(
    height,
    max-height,
    (
        100: 100px,
        200: 200px,
        300: 300px,
        400: 400px,
        500: 500px,
        600: 600px,
        700: 700px,
        800: 800px
    )
);

[class*='height-'] {
    overflow: hidden;
}
