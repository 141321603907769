//----------------------------------------------------------------------
// Layout
//----------------------------------------------------------------------

@use "sass:math";

$grid-breakpoints: (
    xs: 0,
    sm: 320px,
    md: 768px,
    lg: 1200px,
    xl: 1440px
);

$layout-breakpoints: map-merge(
    $grid-breakpoints,
    (
        xxl: 1800px
    )
);

$container-max-widths: (
    xl: 1830px
);

$container-gutters: (
    sm: 15px,
    md: 34px,
    lg: 60px,
    xl: 120px
);

$header-gutters: (
    lg: 60px,
    xl: 93px,
    xxl: 60px
);

$header-logo-width: (
    sm: 68px,
    md: 93px,
    lg: 90px,
    xl: 90px,
    xxl: 118px
);

$header-logo-gutter: 55px;

$grid-gutter-width: 24px;

$grid-gutters: (
    sm:  10px,
    md:  20px,
    lg:  25px,
    xl:  30px,
    xxl: 45px
);

$refinements-gutters: (
    lg:  24px,
    xl:  20px,
    xxl: 32px
);

$search-breakpoint: 1575px;
$search-max-height: 640px;

$spacer:     20px;
$space-half: 10px;
$spacers: (
    0: 0px,
    1: ($spacer),
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5),
    half: $space-half,
    '1-half': ($spacer * 1.5),
    '2-half': ($spacer * 2.5),
    '3-half': ($spacer * 3.5),
    '4-half': ($spacer * 4.5),
);

$picture-ratio: math.div(378, 264);
$picture-search-ratio: math.div(378, 264);
$search-asset-ratio: math.div(493, 968);

//----------------------------------------------------------------------
// Colors
//----------------------------------------------------------------------

$white:    #fff;
$white-opacity: rgba(255, 255, 255, 0.97);
$gray:     #f5f5f4;
$gray-85:  #d9d9d9;
$gray-100: #ececec;
$gray-200: #ccc;
$gray-300: #989898;
$gray-400: #767676;
$gray-500: #c6c3c3;
$gray-600: #292929;
$gray-700: #1b1b1b;
$gray-800: #4e4e4e;
$gray-900: #707070;
$gray-1000: #686868;
$black:    #000;
$red:        #CC0000;
$green:      #45b415;
$green-dark: #0f2a27;
$green-lime: #78AE41;
$pink:       #fce5eb;
$blue:       #34495e;
$orange:     #c28533;

$earthen-brown: #57321D;
$dark-grey: #737373;
$hush-black: #141110;
$hush-white: #fcf9f6;
$boulder-grey: #7E7C7C;
$silver-grey: #B8B8B7;
$light-grey: #E0DEDE;
$mid-grey: #C4C3C3;

$apple-paypal-checkout-button-active: #2C2E2F;
$apple-paypal-checkout-button-hover: #363838;


$dark-gray:  #707070;
$nevada: #67696a;

$theme-colors: (
    'primary':    $black,
    'secondary':  $green-dark,
    'success':    $green,
    'info':       $gray-300,
    'warning':    $orange,
    'danger':     $red,
    'light':      $gray-100,
    'dark':       $gray-200,
    'gray':       $gray,
    'none' :      transparent
);

$swatch-colors: (
    'beige': #f5f5dc,
    'black': $black,
    'blue': $blue,
    'brown': #a52a2a,
    'green': $green,
    'grey': #8f979d,
    'navy': #000080,
    'orange': #ffa500,
    'pink': #fe249a,
    'purple': #800080,
    'red': #f00,
    'white': $white,
    'yellow': #ff0
);

$body-color: $black;
$link-color: $black;
$link-hover-color: $black;
$body-bg: $white;
$back-to-top-color: $black;

//----------------------------------------------------------------------
// Fonts
//----------------------------------------------------------------------

$font-family-theinhardt: "Theinhardt", Arial, sans-serif;
$font-family-theinhardt-medium: "Theinhardt Medium", Arial, sans-serif;
$font-family-tiempos-fine: "Tiempos Fine", Arial, sans-serif;
$font-family-beyond-infinity: "Beyond Infinity", Arial, sans-serif;

$font-family-sans: "ABCROM Book", Arial, sans-serif;
$font-family-serif: "PortraitText", "Times New Roman", serif;
$font-family-base: $font-family-theinhardt;

@if ($feature-redesign) {
    $font-family-base: $font-family-serif;
}

// Font size
$font-size-primary:   13px;
$font-size-secondary: 12px;
$font-size-h1:        36px;
$font-size-h2:        26px;
$font-size-h3:        16px;
$font-size-h4:        14px;
$font-small-icon:     10px;

$font-size-search:    17px;
$font-size-tablet-search: 20px;

// Line height
$line-height-base: 1.11;
$line-height-medium: 1.31;
$line-height-big: 1.5;

// Line weight
$font-weight-bolder: 500;
$font-weight-bold: 400;
$font-weight-standart: 300;

//----------------------------------------------------------------------
// Transition
//----------------------------------------------------------------------

$transition-function            : ease-out;
$transition-function-in-out     : ease-in-out;
$transition-timing-short        : 0.2s;
$transition-timing              : 0.4s;
$transition-timing-long         : 1.2s;
$transition-ease-short          : $transition-function $transition-timing-short;
$transition-ease                : $transition-function $transition-timing;
$transition-ease-logo           : $transition-function-in-out $transition-timing;
$transition-ease-long           : $transition-function $transition-timing-long;

//----------------------------------------------------------------------
// Z-indexes
//----------------------------------------------------------------------
$z-min            : 1;
$z-small          : 2;
$z-medium         : 5;
$z-large          : 50;
$z-max            : 100;

$z-header         : 3;
$z-header-promo   : 2;
$z-hamburger      : 3;
$z-refinements    : 4;
$z-loader         : 4;
$z-modal          : $zindex-modal;
$z-modal-bg       : 1;
$z-minicart       : 3;
$z-reviews        : 3;

//----------------------------------------------------------------------
// Glide JS
//----------------------------------------------------------------------
$glide-class: 'b-carousel';

//----------------------------------------------------------------------
// Modal
//----------------------------------------------------------------------
$modal-md: 792px;
$modal-dialog-margin: 0;
$modal-dialog-margin-y-sm-up: 0;
$modal-content-bg: $white;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-header-padding: 0;
$modal-header-padding-x: 0;
$modal-header-padding-y: 0;
$modal-header-border-width: 0;
$modal-inner-padding: 0 24px 50px;
$modal-footer-margin-between: 10px;

//----------------------------------------------------------------------
// Table
//----------------------------------------------------------------------
$table-cell-padding:          18px 25px;
$table-cell-padding-sm:       18px 10px;
$table-color:                 $black;
$table-border-width:          1px;
$table-border-color:          $gray-100;
$table-head-bg:               $gray-200;
$table-head-color:            $black;

//----------------------------------------------------------------------
// Product Grid
//----------------------------------------------------------------------
$grid-size: 27;
$grid-col: 3;
$grid-row: math.div($grid-size, $grid-col);

//----------------------------------------------------------------------
// Carousels
//----------------------------------------------------------------------
$carousel-gutter: 10px;
$carousel-arrow-gutter: 0;
$carousel-arrow-offset: 30px;

$carousel-gutters: (
    sm: 10px,
    md: 12px,
    lg: 10px,
    xl: 15px,
    xxl: 20px
);
$container-gutters-search: (
    sm: 15px,
    md: 50px,
    lg: 60px,
    xl: 120px
);
$search-carousel-gutters: (
    sm: 6px,
    md: 6px,
    lg: 10px,
    xl: 10px,
    xxl: 15px
);
$carousel-paddings: (
    sm: (map-get($carousel-gutters, sm), map-get($carousel-gutters, sm)),
    md: (map-get($container-gutters, md), map-get($container-gutters, md) + 28px),
    lg: (map-get($container-gutters, lg), map-get($container-gutters, lg)),
    xl: (map-get($container-gutters, xl), map-get($container-gutters, xl))
);
$carousel-paddings-search: (
    sm: (map-get($container-gutters-search, sm), 144px),
    md: (map-get($container-gutters-search, md), map-get($container-gutters-search, md) * 1.5),
    lg: (map-get($container-gutters-search, lg), map-get($container-gutters-search, lg) * 1.5),
    xl: (map-get($container-gutters-search, lg), map-get($container-gutters-search, lg) * 1.5)
);
$carousel-margins: (
    sm: (map-get($container-gutters, sm) - map-get($carousel-gutters, sm), 0)
);

//----------------------------------------------------------------------
// Picture
//----------------------------------------------------------------------
$picture-ratios: (
    hero: (
        xl: percentage(math.div(702, 1440)),
        lg: percentage(math.div(676, 1200)),
        md: percentage(math.div(1060, 1536)),
        sm: percentage(math.div(1040, 640))
    )
);

$global-e-icon-outline: 2px;
$global-e-flag-size: 15px;
$global-e-gutter: 5px;

//----------------------------------------------------------------------
// Navigation
//----------------------------------------------------------------------
$navigation-width: calc(100% - 40px);
$navigation-width-max: 495px;

$search-width: 1520px;
$search-gutter: 50px;

$search-text-gutter: 6px;

$search-input-gutter-side: 30px;
$search-input-height: 34px;

$search-close-button-gutter: 15px;
$search-label-gutter: 0.2em;

$letter-spacing-search: 0.03em;

//----------------------------------------------------------------------
// Base 64 images
//----------------------------------------------------------------------
$base64-zoom-in: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAEgAAAACaqbJVAAABQUlEQVQ4Ea2SPUoEQRBGd/3DQMFIEMFzuIHsouAdvIRXEAMxEW+gJ3BjI2XF1NRYEGTRwMhU1/fGqYWdqYER/eBNd1dXd9V0VbeTawPzADbL7RfGEYzLdW2Yr1gWWZ/AJSzDF6zCNpzBGtzDJzRqnZ0JXMNK4qVtCI+gb6olrDocpruzRn0MaPY1nWIxWluZtWdm5MO+Q/Y7R9ilKn0949lCc3z7cAsfhaXdR98bGIT7AhNL/ByGcowsDKJiffyzLL6eifbomNG/yIxstl7ltoicZRKuW0weYmFGd7AL2WOHX3XUdw9G1Q1LaUnbylapld/DNtcE2jTkBX5PYBOnsu3tbqNlv6nNrA34Bj5Ho4xiyjbbFZyXONfmntnvwCtML+uyyGTH9iH6xMpalDGEvMwAB2BD/0nTzJoy+s3tXrb/DbpoOKZEP9o/AAAAAElFTkSuQmCC';
$base64-zoom-out: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAEgAAAACaqbJVAAABMklEQVQ4Ea2SvUoDQRRG86OSQsFKEMHnMEVwSeE7+BK+hdiIb6BPYGorJcHWNnUgEIIWVmmjnhNmQtadhRHzwcnu3Llz52bv12ykdUy4gJOwPeM5hHlYVx7tX5Fd1tfwAB34ggM4g1s4hFdYQq2O2PmGJ9hPZBkbwBjMTWqPqAlXyd1y0BwvtPuKboh4W67s2jMl+WE/IfV3SokbC3M949mVWvyewwssVpG8H3OfoYjpO7w44mkIWLQI73WPIRsj8Ey0R6PFYiuyI83WDdW8SXJ0StLbZuJWPnYs6Cgdaa60SmX8HtZcmizHkPfkTUATJ6Xtdbe3pTxlzK698AP6UCtvsWXN9gh3Ad+NuWf3PXiHdbEmi5QcgJ6KPnGyTnMOURbzgkvQ0P/SurO6jv5S3WIXPwdHN/FDw6cJAAAAAElFTkSuQmCC';

//----------------------------------------------------------------------
// Bootstrap preferences
//----------------------------------------------------------------------
$enable-prefers-reduced-motion-media-query: false;
