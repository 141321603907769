.b-banner {
    $selector: &;

    color: $white;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;

    img {
        width: 100%;
    }

    &__image {
        display: block;
        position: relative;
        text-align: center;

        .touch &[data-ref="paralax"] {
            top: 0;
            transition: $transition-ease;
        }
    }

    &__content {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        padding: 90px 20px;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__description {
        line-height: 1.42;
        max-width: 230px;
        text-align: left;
    }

    &__subtitle {
        font-weight: 400;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        margin-bottom: 10px;
        text-transform: none;
    }

    &__bottom {
        bottom: 0;
        left: 0;
        margin-bottom: 30px;
        position: absolute;
        right: 0;

        @media (--xxl) {
            margin-bottom: 40px;
        }
    }

    &__footer {
        margin-top: 25px;
        text-align: center;
    }

    &__link {
        @include line-clamping(1);

        display: inline-block;
        font-size: 16px;
        letter-spacing: 0.0625em;
        line-height: 1.625;
    }

    &__sub-link {
        @include line-clamping(1);

        display: inline-block;
        font-size: $font-size-secondary;
        letter-spacing: 0.0625em;
        line-height: 1.5;
    }

    &__button {
        min-width: 205px;

        @media (--lg-up) {
            min-width: 192px;
        }

        @media (--xxl) {
            min-width: 250px;
        }
    }

    &--main {
        max-height: 100vh;
        overflow: hidden;

        #{$selector}__content {
            padding-left: 74px;
            padding-right: 74px;

            @media (--xl) {
                padding-left: 150px;
                padding-right: 150px;
            }

            @media (--xxl) {
                margin-left: auto;
                margin-right: auto;
                max-width: 1920px;
                padding-left: 208px;
                padding-right: 208px;
            }

            @media (--md) {
                padding-left: 35px;
                padding-right: 35px;
            }

            @media (--sm) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        #{$selector}__video {
            @media (--sm) {
                margin-left: calc(-82vh + 50%);
                width: 164vh;
            }
        }

        #{$selector}__title {
            margin-bottom: 40px;

            @media (--xxl) {
                font-size: 47px;
                margin-bottom: 55px;
            }
        }

        #{$selector}__subtitle {
            margin-bottom: 16px;

            @media (--xxl) {
                font-size: 21px;
                margin-bottom: 21px;
                margin-top: 60px;
            }
        }

        .btn {
            min-width: 192px;

            @media (--xxl) {
                font-size: 15px;
                padding: 20px 50px;
            }
        }
    }

    &--search {
        overflow: hidden;

        @media (--sm) {
            max-height: 145px;
        }

        @media (--md-up) {
            max-height: 220px;
        }
    }

    &--inline {
        #{$selector}__title {
            @media (--sm) {
                .grid-alternative-view & {
                    font-size: 18px;
                }
            }
        }

        #{$selector}__link {
            bottom: 30px;
            line-height: normal;

            @media (--sm) {
                .grid-alternative-view & {
                    bottom: 25px;
                }
            }
        }
    }

    &--error {
        font-size: 14px;
        letter-spacing: 0.08em;
        line-height: 1.21;

        #{$selector}__content {
            @media (--sm) {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media (--md) {
                max-width: 658px;
                padding-left: 95px;
                padding-right: 95px;
            }

            @media (--lg-up) {
                padding-left: 152px;
                padding-right: 152px;
            }

            @media (--xxl) {
                margin-left: auto;
                margin-right: auto;
                max-width: 1920px;
                padding-left: 300px;
                padding-right: 300px;
            }
        }

        .btn {
            letter-spacing: 0.1em;
            min-width: 224px;
            padding-bottom: 16px;
            padding-top: 16px;

            @media (--sm) {
                min-width: 260px;
            }
        }
    }

    + .b-article {
        padding-top: 100px;

        @media (--sm) {
            padding-top: 50px;
        }
    }

    .t-link,
    .t-link--white,
    .t-link--gray {
        @include animated-link-hover;
    }

    .t-link--invert,
    .t-link--white-invert,
    .t-link--gray-invert {
        @include animated-link-hover($invert: true);
    }

    &__title {
        b, strong {
            font-weight: 700;
        }
    }
}

.b-banner-background {
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
