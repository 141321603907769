.a-opacity-hover {
    opacity: 1;
    transition: opacity $transition-ease;

    .non-touch &:hover {
        opacity: 0.5;
    }

    &:hover {
        text-decoration: none;
    }
}

.a-opacity-scroll {
    opacity: 1;
    transition: opacity $transition-ease;

    &:not(.m-animate) {
        opacity: 0;
    }
}
