$movement-values: (
    top: (
        self: "0, 20px, 0",
        child: "0, 20px, 0"
    )
);

[class*='a-move-'] {
    &,
    .a-element {
        transition: $transition-ease-long;
        transition-property: transform, opacity;
        transition-duration: 1s, 2s;
    }

    .a-element {
        @for $i from 1 through 10 {
            &:nth-child(#{$i}n) {
                transition-delay: $i * $transition-timing;
            }
        }
    }

    &:not(.m-animate) {
        opacity: 0;

        .a-element {
            opacity: 0;
        }
    }
}

.a-move {
    @each $key, $value in $movement-values {
        &-#{$key} {
            &:not(.m-animate) {
                transform: translate3d(#{map-get($value, self)});

                .a-element {
                    transform: translate3d(#{map-get($value, child)});
                }
            }
        }
    }

    @each $media, $width in $grid-breakpoints {
        @each $key, $value in $movement-values {
            @media (--#{$media}-up) {
                &-#{$media}-#{$key} {
                    &:not(.m-animate) {
                        transform: translate3d(#{map-get($value, self)});

                        .a-element {
                            transform: translate3d(#{map-get($value, child)});
                        }
                    }
                }
            }
        }
    }
}

.a-move-elements {
    &:not(.m-animate) {
        opacity: 1;
        transform: none;
    }
}
