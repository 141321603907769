$grid-types: (
    1: (
        gutters: (
            sm:  15px,
            md:  20px,
            lg:  8px,
            xl:  6px,
            xxl: 8px,
        )
    ),
    2: (
        gutters: (
            sm:  12px,
            md:  20px,
            lg:  24px,
            xl:  6px,
            xxl: 24px,
        ),
        paddings: (
            sm:  15px,
            md:  34px,
            lg:  128px,
            xl:  257px,
            xxl: 302px,
        )
    ),
    3: (
        gutters: (
            sm:  40px,
            md:  20px,
            lg:  12px,
            xl:  16px,
            xxl: 16px,
        ),
        paddings: (
            sm:  35px,
            md:  34px,
            lg:  102px,
            xl:  222px,
            xxl: 302px,
        )
    ),
    4: (
        gutters: (
            sm:  30px,
            md:  20px,
            lg:  8px,
            xl:  40px,
            xxl: 52px,
        ),
        paddings: (
            sm:  35px,
            md:  74px,
            lg:  0,
            xl:  40px,
            xxl: 42px,
        )
    ),
    5: (
        gutters: (
            sm: 20px,
            md: 38px,
            lg: 58px,
            xl: 65px,
            xxl: 70px,
        ),
        margins: (
            md: -38px,
            lg: -51px,
            xl: -51.5px,
            xxl: -54.5px,
        ),
        paddings: (
            sm: 20px,
        )
    ),
    6: (
        gutters: (
            sm: 40px,
            md: 3px,
            lg: 6px,
            xl: 7px,
            xxl: 7px,
        ),
        paddings: (
            sm: 20px,
        )
    ),
);

@each $type, $grid in $grid-types {
    .grid-type-#{$type} {
        $paddings: map-get($grid, paddings);
        @if $paddings {
            @each $media, $padding in $paddings {
                @media (--#{$media}) {
                    padding-left: $padding;
                    padding-right: $padding;
                }
            }
        }

        $margins: map-get($grid, margins);
        @if $margins {
            @each $media, $margin in $margins {
                @media (--#{$media}) {
                    margin-left: $margin;
                    margin-right: $margin;
                }
            }
        }

        @include setup-grid-gutters(map-get($grid, gutters));
    }
}
